<template>
  <div class="pb-4 mt-4">
    <h2
      class="d-flex justify-space-between"
    >
      Accounts für die {{ readablePlatformName }} App
    </h2>
    <h4>
      Wer in Ihrem Unternehmen soll Zugang zur {{ readablePlatformName }} App erhalten?
      Nach erfolgreicher Aktivierung erhalten alle diese Personen eine E-Mail,
      um jeweils ein persönliches Passwort festzulegen.
    </h4>
    <v-card
      v-for="(user, index) in users"
      :key="index"
      outlined
      class="my-4 pa-4"
      :class="{'ml-0 mr-0 pl-2 pr-2': $vuetify.breakpoint.smAndDown}"
    >
      <v-row
        class="d-flex bottom-2 flex-nowrap"
        :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
      >
        <v-col
          cols="12"
          md="11"
        >
          <v-row
            class="d-flex bottom-2"
          >
            <v-col
              cols="12"
              md="2"
            >
              <v-select
                ref="salutation"
                v-model.lazy="user.salutation"
                outlined
                dense
                hide-details
                label="Anrede"
                :disabled="alreadySaved(user)"
                :items="salutations"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              <v-text-field
                ref="billing.firstname"
                v-model.lazy="user.firstname"
                outlined
                dense
                hide-details
                label="Vorname"
                :disabled="alreadySaved(user)"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              <v-text-field
                ref="billing.lastname"
                v-model.lazy="user.lastname"
                outlined
                dense
                hide-details
                label="Nachname"
                :disabled="alreadySaved(user)"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              xl="5"
            >
              <v-text-field
                ref="email"
                v-model.lazy="user.email"
                outlined
                hide-details
                dense
                label="E-Mail"
                :rules="[rules.email, rules.required]"
                :disabled="alreadySaved(user)"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-checkbox
                ref="receiveNotificationEmails.leadAction"
                v-model.lazy="user.receiveNotificationEmails.leadAction"
                hide-details
                dense
                class="mt-0"
                label="Erhält alle Notifikationen aus der App"
                :disabled="alreadySaved(user)"
              />
            </v-col>
            <v-col
              v-if="hasNewsletter"
              cols="12"
              md="6"
            >
              <v-checkbox
                ref="receiveNotificationEmails.newsletter"
                v-model.lazy="user.receiveNotificationEmails.newsletter"
                hide-details
                dense
                class="mt-0"
                label="Erhält Informationen zu Produkt-Updates und Kampagnen"
                :disabled="alreadySaved(user)"
              />
            </v-col>

            <v-col
              v-if="allowPassiveUsers || user.isPassive"
              cols="12"
            >
              <v-switch
                ref="isPassive"
                v-model.lazy="user.isPassive"
                label="Passiver Account ohne App-Zugriff"
                hide-details
                :disabled="alreadySaved(user)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="!alreadySaved(user)"
            class="justify-center px-4 py-2 d-flex align-center"
            color="#FDD"
            elevation="0"
            @click.stop="() => removeFromList(index)"
          >
            <v-icon color="red">
              mdi-delete
            </v-icon>
          </v-btn>
          <v-card
            v-else
            elevation="0"
            class="justify-center px-4 py-2 d-flex align-center green lighten-5"
          >
            <v-icon
              color="success"
            >
              mdi-check
            </v-icon>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.COMPANY_USER"
    >
      <v-row
        v-if="!hasMaxUsers(feature.config.maxUsers)"
      >
        <v-col class="justify-end d-flex">
          <v-tooltip
            bottom
            :disabled="isAddNewAllowed"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  :disabled="!isAddNewAllowed"
                  @click.stop="addToList"
                >
                  Hinzufügen
                  <v-icon color="grey lighten-1">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>
              Bitte vervollständigen Sie zuerst alle vorhandenen Accounts
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </Feature>
  </div>
</template>

<script>
import { email, required } from '@/lib/validation'
import { SALUTATIONS } from './labels'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'

export default {
  components: { Feature },

  mixins: [brandingMixin],

  props: {
    users: { type: Array, default: () => ([]) }
  },

  data () {
    return {
      featureNames
    }
  },

  computed: {
    isAddNewAllowed () {
      return this.users.every(({ salutation, firstname, lastname, email }) => [salutation, firstname, lastname, email].every(Boolean))
    },

    hasNewsletter () {
      return this.$features.feature(featureNames.APP_BRANDING).config?.hasNewsletter
    },

    allowPassiveUsers () {
      return this.$features.feature(featureNames.COMPANY_USER).config?.allowPassiveUsers
    }
  },

  created () {
    this.rules = { email, required }
    this.salutations = SALUTATIONS
  },

  methods: {
    hasMaxUsers (maxUsers) {
      return this.users.length >= maxUsers
    },

    addToList () {
      this.users.push({
        receiveNotificationEmails: {
          leadAction: true,
          newsletter: true
        },
        isPassive: false
      })
    },

    removeFromList (index) {
      this.users.splice(index, 1)
    },

    alreadySaved (user) {
      return Boolean(user.id)
    }
  }
}
</script>
