const variantToImageEnding = {
  DV1: 'v1',
  CHV1: 'v1',
  ATV1: 'v1',
  DV2: 'v2',
  CHV2: 'v2',
  DV3: 'v3',
  DV4: 'v4',
  REMAX: 'remax',
  VOLKSBANK: 'voba'
}

export default function heroImage ({ variant, lpSlug, defaultForBasic = false }) {
  const image = (defaultForBasic || !variantToImageEnding[variant])
    ? 'hero.jpg?w=500'
    : `hero-${variantToImageEnding[variant]}.jpg?w=500`
  return `https://static.bottimmo.com/images/assets/${lpSlug}/${image}`
}
