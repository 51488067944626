<template>
  <div>
    <div class="pb-4 mb-2">
      <h2 class="pr-4 ml-n2">
        Anschrift Ihres Unternehmens
      </h2>
    </div>
    <v-row>
      <v-text-field
        ref="name"
        v-model.lazy="data.name"
        outlined
        label="Firmenname"
        placeholder="Max Makler Immobilien"
        :rules="[rules.length({max: 90}), rules.optionalForOnboarding(isOnboarding) ]"
        validate-on-blur
      />
    </v-row>
    <v-row>
      <v-text-field
        ref="street"
        v-model.lazy="data.street"
        outlined
        :rules="[rules.optionalForOnboarding(isOnboarding)]"
        label="Straße und Hausnummer"
        placeholder="Musterstraße 3"
        validate-on-blur
      />
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="3"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="zip"
          v-model.lazy="data.zip"
          outlined
          label="PLZ"
          type="number"
          placeholder="12345"
          :rules="[rules.zip, rules.length({min: 4, max: 5}), rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="city"
          v-model.lazy="data.city"
          outlined
          label="Stadt"
          placeholder="Musterstadt"
          validate-on-blur
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="pa-0"
      >
        <v-select
          ref="country"
          v-model.lazy="data.country"
          outlined
          label="Land"
          validate-on-blur
          :items="countries"
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-col>
    </v-row>
    <div class="pb-4 mb-2">
      <h2 class="pr-4 ml-n2">
        Webauftritt Ihres Unternehmens
      </h2>
    </div>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="website"
          v-model.lazy="data.website"
          outlined
          type="url"
          label="URL zu Ihrer Website"
          placeholder="https://www.max-makler.de"
          :rules="[rules.url, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="about"
          v-model.lazy="data.about"
          type="url"
          outlined
          label="URL zu Ihrer Über uns / Teamseite"
          placeholder="https://www.max-makler.de/team"
          :rules="[rules.url, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="imprint"
          v-model.lazy="data.imprint"
          type="url"
          outlined
          label="URL zu Ihrem Impressum"
          placeholder="https://www.max-makler.de/impressum"
          :rules="[rules.url, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="privacy"
          v-model.lazy="data.privacy"
          type="url"
          outlined
          label="URL zu Ihrer Datenschutzerklärung"
          placeholder="https://www.max-makler.de/datenschutz"
          :rules="[rules.url, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
    </v-row>

    <div class="pb-4 mb-2">
      <h2 class="pr-4 ml-n2">
        Weitere Angaben
      </h2>
    </div>

    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pa-0"
        :class="{'pr-0': $vuetify.breakpoint.smAndDown, 'pr-2': $vuetify.breakpoint.mdAndUp}"
      >
        <v-text-field
          ref="commission"
          v-model.lazy="data.commission"
          type="number"
          min="0"
          max="100"
          suffix="%"
          outlined
          label="Ihr Provisionssatz"
          placeholder="3,5"
          validate-on-blur
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { zip, url, length, optionalForOnboarding } from '@/lib/validation'
import { COUNTRIES } from './labels'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.countries = COUNTRIES
    this.rules = { zip, url, length, optionalForOnboarding }
  }

}
</script>
