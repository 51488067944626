<template>
  <Feature
    v-slot="{feature}"
    :feature-slug="featureNames.COMPANY"
  >
    <div class="mid-gray">
      <Feature :feature-slug="featureNames.ONBOARDING_PREVIEW">
        <div>
          <div v-if="isOnboarding && data.previewLink">
            <v-alert
              class="mx-n4"
              type="info"
              text
            >
              Sie möchten wissen, an welchen Stellen Ihre Daten und Bilder erscheinen?
              <a
                :href="data.previewLink"
                target="_blank"
                rel="noopener noreferrer"
              >Beispiel-Werbemittel ansehen</a>
            </v-alert>
          </div>
        </div>
      </Feature>
      <div>
        <h2 class="pr-4 ml-n2">
          Kontaktdaten
        </h2>
      </div>

      <div class="pb-4 mb-2">
        <h4 class="pr-4 ml-n2">
          Wer soll in den Ratgebern und Checklisten sowie auf den Landingpages als Autor / Ansprechpartner erscheinen?
        </h4>
      </div>
      <v-row
        class="d-flex justify-space-between"
        :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
      >
        <v-col
          cols="12"
          md="2"
          class="pr-0 pa-0 pr-md-2"
        >
          <v-text-field
            ref="ambassador.salutation"
            v-model.lazy="data.ambassador.salutation"
            outlined
            label="Anrede"
            placeholder="Herr"
            validate-on-blur
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="pr-0 pa-0 pr-md-2"
        >
          <v-text-field
            id="ambassador_firstname"
            ref="ambassador.firstname"
            v-model.lazy="data.ambassador.firstname"
            outlined
            label="Vorname"
            placeholder="Max"
            validate-on-blur
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="pa-0"
        >
          <v-text-field
            ref="ambassador.lastname"
            v-model.lazy="data.ambassador.lastname"
            outlined
            label="Nachname"
            placeholder="Makler"
            validate-on-blur
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-text-field
          ref="ambassador.role"
          v-model.lazy="data.ambassador.role"
          outlined
          label="Funktion / Rolle"
          placeholder="Geschäftsführer"
          validate-on-blur
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-row>
      <div class="pb-4 mb-2">
        <h4 class="pr-4 ml-n2">
          Welche E-Mail-Adresse und Telefonnummer soll in den Kontaktdaten stehen?
          Diese Adresse ist auch der Absender der E-Mail-Serien.
        </h4>
      </div>
      <v-row class="d-flex justify-space-between">
        <v-col
          cols="12"
          sm="6"
          class="pr-0 pa-0 pr-md-2"
        >
          <PhoneField
            id="ambassador_phone"
            v-model="data.ambassador.phone"
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
            validate-on-blur
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="pa-0"
        >
          <PhoneField
            id="ambassador_mobile"
            v-model="data.ambassador.mobile"
            label="Mobilnummer"
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
            validate-on-blur
          />
        </v-col>
      </v-row>

      <v-row class="d-flex justify-space-between">
        <v-text-field
          ref="ambassador.email"
          v-model.lazy="data.ambassador.email"
          type="email"
          outlined
          label="E-Mail"
          placeholder="info@max-makler.de"
          :rules="[rules.email, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
          :disabled="!isOnboarding"
        />
      </v-row>
      <div class="pb-4 mb-2">
        <h2 class="pr-4 ml-n2">
          Das Motto Ihres Unternehmens
        </h2>
      </div>
      <v-row>
        <v-text-field
          ref="slogan"
          v-model.lazy="data.slogan"
          outlined
          label="Slogan"
          placeholder="Immobilienverkauf? - Mit Ihren Experten aus Musterstadt ganz einfach."
          validate-on-blur
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-row>

      <div v-if="feature.config.isColorEditable">
        <div class="pb-4">
          <h2 class="pr-4 d-inline">
            Ihre Farben
          </h2>
        </div>
        <div class="pb-4">
          <h4 class="pb-4 d-inline">
            Bitte achten Sie bei der Farbwahl auf genügend Kontrast zu weiss.
          </h4>
        </div>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="pr-2 pa-0 align-center d-flex flex-column"
          >
            <div class="px-4">
              <h4>
                Was ist Ihre primäre Farbe?
              </h4>
              <ColorPicker v-model.lazy="data.theme.colors.primary" />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pr-2 pa-0 align-center d-flex flex-column"
          >
            <div class="px-4">
              <h4>
                Was ist Ihre sekundäre Farbe?
              </h4>
              <ColorPicker v-model.lazy="data.theme.colors.secondary" />
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="pt-4 pb-4">
        <h2 class="pr-4 ml-n2">
          Bilder
        </h2>
      </div>
      <v-row>
        <v-col
          v-if="feature.config.isLogoEditable"
          cols="12"
          md="6"
          lg="4"
          class="mb-2 pa-0 pa-sm-2 mb-sm-0"
        >
          <v-card
            class="pa-6"
            height="100%"
          >
            <h4 class="pb-2">
              Logo
            </h4>
            <div class="mb-4">
              Mindestens 1200 Pixel breit, transparent, png-Format
            </div>
            <CropImageUpload
              :min-height="100"
              :min-width="1200"
              :min-opacity="0.01"
              accept="image/png"
              :save="(image) => saveImage(image, 'logo')"
            />
            <v-img
              :src="images.logo || data.images.logo.url"
              height="200px"
              contain
              class="mt-4"
            />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="mb-2 pa-0 pa-sm-2 mb-sm-0"
        >
          <v-card class="pa-6">
            <h4 class="pb-2">
              Teambild
            </h4>
            <div class="mb-4">
              Mindestens 1200x800 Pixel, Querformat, jpg-Format
            </div>
            <CropImageUpload
              :min-height="800"
              :min-width="1200"
              :ratio="1.5"
              accept="image/jpg"
              :save="(image) => saveImage(image, 'team')"
            />
            <v-img
              :src="images.team || data.images.team.url"
              height="200px"
              contain
              class="mt-4"
            />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="mb-2 pa-0 pa-sm-2 mb-sm-0"
        >
          <v-card class="pa-6">
            <h4 class="pb-2">
              Portrait
            </h4>
            <div class="mb-4">
              Mindestens 1200x1200 Pixel, Quadratisch, jpg-Format
            </div>
            <CropImageUpload
              :min-height="1200"
              :min-width="1200"
              :ratio="1"
              accept="image/jpeg"
              :save="(image) => saveImageAmbassador(image, 'image')"
            />
            <v-img
              :src="ambassadorImages.image || data.ambassador.image.url"
              height="200px"
              contain
              class="mt-4"
            />
          </v-card>
        </v-col>
        <v-col
          v-if="!isOnboarding"
          cols="12"
          md="6"
          lg="4"
          class="mb-2 pa-0 pa-sm-2 mb-sm-0"
        >
          <v-card
            class="pa-6"
            height="100%"
          >
            <h4 class="pb-2">
              Regionenbild (optional)
            </h4>
            <div class="mb-4">
              Mindestens 1200x800 Pixel, Querformat, jpg-Format
            </div>
            <CropImageUpload
              :min-height="800"
              :min-width="1200"
              :ratio="1.5"
              accept="image/jpg"
              :save="(image) => saveImage(image, 'region')"
            />
            <v-img
              :src="images.region || data.images.region.url"
              height="200px"
              contain
              class="mt-4"
            />
          </v-card>
        </v-col>
        <v-col
          v-if="false"
          cols="12"
          md="6"
          lg="4"
          class="mb-2 pa-0 pa-sm-2 mb-sm-0"
        >
          <v-card
            class="pa-6"
            height="100%"
          >
            <h4 class="pb-2">
              Büro (optional)
            </h4>
            <div class="mb-4">
              Mindestens 1200x800 Pixel, Querformat, jpg-Format
            </div>
            <CropImageUpload
              :min-height="800"
              :min-width="1200"
              :ratio="1.5"
              accept="image/jpg"
              :save="(image) => saveImage(image, 'office')"
            />
            <v-img
              :src="images.office || data.images.office.url"
              height="200px"
              contain
              class="mt-4"
            />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="mb-2 pa-0 pa-sm-2 mb-sm-0"
        >
          <v-card
            class="pa-6"
            height="100%"
          >
            <h4 class="pb-2">
              Unterschrift
            </h4>
            <div class="mb-4">
              Mindestens 1200 Pixel breit, png-Format
            </div>
            <CropImageUpload
              :min-height="100"
              :min-width="1200"
              :min-opacity="0.5"
              accept="image/png"
              remove-background
              :save="(image) => saveImageAmbassador(image, 'autograph')"
            />
            <v-img
              :src="ambassadorImages.autograph || data.ambassador.autograph.url"
              height="200px"
              contain
              class="mt-4"
            />
          </v-card>
        </v-col>
      </v-row>
      <Feature :feature-slug="featureNames.CONTRACT">
        <div>
          <div
            v-if="isOnboarding"
            class="pt-4"
          >
            <h2 class="pr-4 d-inline">
              Logo-Freigabe
            </h2>
          </div>
          <v-row v-if="isOnboarding">
            <v-col>
              <h4 class="pb-4">
                Sie sind ein wertvoller Kunde und wir würden uns freuen, Sie als unseren Referenz-Kunden zu präsentieren. Dürfen wir Ihr Logo auf unserer Webseite platzieren?
              </h4>
              <v-radio-group
                v-model="data.isReferenceCustomer"
                row
                :rules="[rules.optionalBooleanForOnboarding(isOnboarding)]"
              >
                <v-radio
                  label="Ja, gerne dürfen Sie uns als Referenz-Kunden aufnehmen."
                  :value="true"
                />
                <v-radio
                  label="Nein, ich möchte nicht auf Ihrer Webseite sichtbar sein."
                  :value="false"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
      </Feature>

      <template>
        <div class="pt-4">
          <h2 class="pr-4 d-inline">
            Signatur
          </h2>
        </div>
        <h4 class="pb-4 pr-4 d-inline">
          {{ signatureDescription }}
        </h4>

        <v-switch
          v-if="defaultSignatureAvailable"
          v-model="data.ambassador.signature.customTemplateSignature"
          label="Ich möchte lieber eine eigene Signatur aufsetzen und nutzen."
        />
        <v-row
          class="mt-2"
        >
          <v-col
            cols="12"
            md="6"
          >
            <div class="signature__wrapper">
              <v-textarea
                v-model.lazy="data.ambassador.signature.text"
                outlined
                label="Signatur"
                :rules="[rules.optionalForOnboarding(isOnboarding)]"
                :disabled="!data.ambassador.signature.customTemplateSignature"
              />
              <v-dialog
                v-model="previewTextSignature"
                max-width="600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="preview__icon"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-eye
                    </v-icon> <span>Vorschau Text Signatur</span>
                  </span>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h6">Text Vorschau</span>
                  </v-card-title>
                  <v-card-text>
                    <signature-preview
                      :company="data"
                      type="text"
                      :custom-signature="data.ambassador.signature.customTemplateSignature ? data.ambassador.signature.text : ''"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div class="signature__wrapper">
              <v-textarea
                v-model.lazy="data.ambassador.signature.html"
                outlined
                label="HTML Signatur (optional)"
                :disabled="!data.ambassador.signature.customTemplateSignature"
              />
              <v-dialog
                v-model="previewHTMLSignature"
                max-width="600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="preview__icon"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-eye
                    </v-icon> <span>Vorschau HTML Signatur</span>
                  </span>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h6">HTML Vorschau</span>
                  </v-card-title>
                  <v-card-text>
                    <signature-preview
                      :company="data"
                      type="html"
                      :custom-signature="data.ambassador.signature.customTemplateSignature ? data.ambassador.signature.html : ''"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>
  </Feature>
</template>

<script>
import { email, optionalForOnboarding, optionalBooleanForOnboarding } from '@/lib/validation'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import CropImageUpload from '../CropImageUpload.vue'
import ColorPicker from '../ColorPicker.vue'
import PhoneField from '@/components/PhoneField.vue'
import { isExpertUser } from '../../lib/productTypes'
import heroImage from '@/lib/customHeroMapper'
import SignaturePreview from '@/modules/onboarding/SignaturePreview.vue'

export default {
  components: { CropImageUpload, ColorPicker, Feature, PhoneField, SignaturePreview },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    isOnboarding: {
      type: Boolean,
      default: false
    },
    saveAmbassadorImage: {
      type: Function,
      required: true
    },
    saveCompanyImage: {
      type: Function,
      required: true
    },
    deleteCompanyImage: {
      type: Function,
      default: () => {}
    },
    variant: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      images: {},
      ambassadorImages: {},
      defaultSignatures: {
        text: 'default text',
        html: '<h3>default html</h3>'
      },
      previewHTMLSignature: false,
      previewTextSignature: false
    }
  },
  computed: {
    isExpert () {
      return isExpertUser(this.$auth.user)
    },
    hasSetValuationHeroImage () {
      return this.images.lpValuationHero || !!this.data?.images?.lpValuationHero?.url
    },
    valuationHeroImage () {
      return this.data?.images?.lpValuationHero?.url || heroImage({ variant: this.variant, lpSlug: 'immobilienbewertung', defaultForBasic: !this.isExpert })
    },
    defaultSignatureAvailable () {
      return this.data.mailProvider === 'HOPPERMATION'
    },
    signatureDescription () {
      if (this.data.mailProvider === 'HOPPERMATION') {
        return 'Die Mails an Ihre Kontakte werden mit einer Standardsignatur versendet. Diese setzt sich aus den angegebenen Kontaktdaten zusammen. Alternativ haben Sie hier die Möglichkeit eine eigene Signatur anzugeben.'
      }
      return 'Diese E-Mail Signatur wird in den automatisch erzeugten E-Mails verwendet, die an Ihre Leads versendet werden.'
    }
  },
  created () {
    this.rules = { email, optionalForOnboarding, optionalBooleanForOnboarding }
    this.featureNames = featureNames
  },
  methods: {
    saveImage (image, type) {
      this.$set(this.images, type, URL.createObjectURL(image))
      this.saveCompanyImage(image, type)
    },
    deleteImage (type) {
      this.$set(this.images, type, null)
      this.deleteCompanyImage(type)
    },
    saveImageAmbassador (image, type) {
      this.$set(this.ambassadorImages, type, URL.createObjectURL(image))
      this.saveAmbassadorImage(image, type)
    }
  }
}
</script>
<style>
.signature-preview-wrapper {
  padding: 16px 0;
  margin: 0 16px;
}
.signature__wrapper {
  position: relative;
}
.preview__icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
}
.preview__iframe {
  border: none;
}
</style>
