var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.company)?_c('step-container',_vm._g({attrs:{"required-data":_vm.requiredData,"data":{contactPerson: _vm.contactPerson,
          contactPersonContract: _vm.contactPersonContract,
          contactAdAgency: _vm.contactAdAgency,
          contactWebsite: _vm.contactWebsite,
          users: _vm.users },"on-submit":_vm.submit,"has-steps":_vm.isOnboarding}},_vm.$listeners),[(_vm.isOnboarding)?_c('contacts-data',_vm._b({attrs:{"is-onboarding":""}},'contacts-data',{contactPerson: _vm.contactPerson,
             contactPersonContract: _vm.contactPersonContract,
             contactAdAgency: _vm.contactAdAgency,
             contactWebsite: _vm.contactWebsite,
             users: _vm.users },false)):_c('v-card',{attrs:{"flat":""}},[_c('contacts-data',_vm._b({staticClass:"mx-4"},'contacts-data',{contactPerson: _vm.contactPerson,
               contactPersonContract: _vm.contactPersonContract,
               contactAdAgency: _vm.contactAdAgency,
               contactWebsite: _vm.contactWebsite,
               users: _vm.users },false))],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }